import { ModalBase } from "../modal_base";
import { AjaxPromise } from "../../shared/ajax_utilities";
import { displayErrors } from "../../shared/common";

export class ListDeleteModal extends ModalBase {
    constructor() {
        super('list_delete_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.title__content').text($modalTriggerBtn.attr('title') ?? '');
        this.MODAL_CONTAINER_EL.find('.delete-mode').text($modalTriggerBtn.hasClass('enable') ? 'enable' : 'delete');
    }

    setupModalEvents($btn, additionalData) {
        const $modal = this.MODAL_CONTAINER_EL;
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const type = ( $btn.hasClass('fa') ) ? 'gallery' : 'list';
            const $row = ( type === 'list' ) ? $btn.parent().parent() : $btn.parent();
            const delete_id = $btn.attr('id').replace('list_','');
            const action = ( $btn.hasClass('enable') ) ? 're-enable' : 'remove';
            const colour = ( action === 'remove' ) ? '#FF9494' : '#bddcc1';
            const url = $btn.attr('href');
            
            let data = {
                'item': delete_id
            };
            let errors = [];
            const inputs = ( $btn.attr('data-input') ) ?  $btn.attr('data-input').split(',') : [];
            let $inputs = [];

            // check for required notes
            $.each(inputs, function(k, input){
                const $input = $modal.find(`#remodal_confirm_${input}`);

                if( !$btn.attr('data-unrequire-all') && $input.hasClass('required') ) {
                    errors = app.LIST.checkErrors($btn, [$input]);
                }

                data[input] = $input.val();
                $inputs.push($input);
            });

            // re-open modal
            if( errors.length > 0 ) {
                return false;
            }

            // update row colour
            if( type === 'list' ) {
                $row.css('background-color', colour);
            }

            // get data to send
            data = $.extend({}, $btn[0].dataset, data);

            try {
                const res = await AjaxPromise({
                    type: 'POST',
                    url: url,
                    data: data
                });

                // move duplicate thick marker to next
                if (app.URI[4] === "duplicates") {
                    const $next_row = $row.next();
                    if ($next_row.hasClass("duplicate_end") && $row.hasClass('duplicate') ) {
                        $next_row.addClass("duplicate");
                    }
                }

                if( res.status === 'success' ) {

                    // reset form
                    $inputs.forEach( el => {
                        if( el[0].tagName.toLowerCase() === 'select' ) {
                            $(el).find('option').eq(0).prop('selected', true);
                        } else {
                            $(el).val('');
                        }
                    });

                    // hide row
                    $row.addClass('fadeOut').fadeOut('slow');

                    // update total
                    if(app.DOM.table_total && app.DOM.table_total.length > 0) {
                        app.DOM.table_total.html(parseInt(app.DOM.table_total.html()) - 1);
                    }

                    app.MODAL_CORE.closeModal(this.MODAL_ID);
                } else {
                    displayErrors(res, 'list_delete_modal');
                }
            } catch(err) {
                displayErrors(err, 'list_delete_modal');
            }
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}